<template>
  <b-modal
    class="hapus"
    hide-footer
    hide-header
    centered
    id="modalDeleteClient"
    size="md"
  >
    <div
      class="content"
      style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
    >
      <span
        class="tittle"
        style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
      >
        Apakah anda yakin mengarsip {{ data.namaClient }}?
      </span>
    </div>
    <div style="margin-top: 4%">
      <b-form-group
        label-size="lg"
        label-cols="4"
        label-cols-lg="3"
        label="Password"
        label-for="input-formatter"
      >
        <b-form-input
          id="input-formatter"
          placeholder="Masukan Password"
          style="border-radius: 10px; margin-bottom: 15px"
          size="lg"
          type="password"
          v-model="password"
        >
        </b-form-input>
      </b-form-group>
    </div>
    <div
      class="wrapper"
      style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
    >
      <div
        class="button-wrapper"
        style="display: flex; width: 95%; justify-content: flex-end"
      >
        <b-button
          @click="doDelete"
          :disabled="loading || !password"
          style="
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
        >
          <span v-if="!loading">Arsipkan</span>
          <WhiteSpinner v-else />
        </b-button>
        <b-button
          variant="danger"
          @click="batalDelete()"
          :disabled="loading"
          style="
              border: none;
              margin-left: 10px;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
        >
          <span v-if="!loading">Batal</span>
          <WhiteSpinner v-else />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from "@/ip_config.js";
import WhiteSpinner from "@/components/spinner/white-spinner.vue";
export default {
  components: { WhiteSpinner },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      password: "",
    };
  },
  methods: {
    async doDelete() {
      let vm = this;
      vm.loading = true;

      try {
        let archive = await axios.post(
          "client/delete",
          {
            id: vm.data.clientid,
            password: vm.password,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        );
        if (archive.data.message == "password salah") {
          vm.loading = false;
          vm.$bvModal.hide("modalDeleteClient");
          vm.$emit("alertFromChild", {
            pesan: "Password Salah!",
            variant: "danger",
            show: true,
          });
        } else {
          vm.loading = false;
          vm.$bvModal.hide("modalDeleteClient");
          vm.$emit("alertFromChild", {
            pesan: `Berhasil Mengarsipkan ${vm.data.namaClient}`,
            variant: "success",
            show: true,
          });
          vm.password = "";
          vm.$store.dispatch("apiListClient/getData");
        }
      } catch (error) {
        vm.loading = false;
        vm.$bvModal.hide("modalDeleteClient");
        vm.$emit("alertFromChild", {
          pesan: "Koneksi ke Server Terganggu, Cobalah Beberapa Saat Lagi!",
          variant: "danger",
          show: true,
        });
      }
    },
  },
};
</script>

<style></style>
