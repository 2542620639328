<template>
  <b-modal hide-footer hide-header centered id="modalInviteUser">
    <div class="tutup" style="display: flex; justify-content: flex-end">
      <b-button
        @click="$bvModal.hide('modalInviteUser')"
        style="background-color: white; border: none"
        ><img
          src="../../../../assets/icon/close.svg"
          alt=""
          style="width: 15px; height: 15px"
      /></b-button>
    </div>
    <div
      style="
            text-align: center;
            font-size: 22px;
            font-weight: 700;
            font-family: 'nunito', sans-serif;
          "
    >
      Undang Akun
    </div>
    <div style="margin-bottom: 6%">
      <label class="typo__label">Masukan Email</label>
      <b-form-input
        id="input-1"
        v-model="email"
        type="email"
        placeholder="Enter email"
        required
      ></b-form-input>
    </div>

    <span class="peran" style="font-family: 'nunito', sans-serif">Peran</span>

    <b-form-radio-group
      label="Peran"
      v-slot="{ ariaDescribedby }"
      style="font-family: 'nunito', sans-serif"
    >
      <b-form-radio
        v-model="jabatan"
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        value="manager"
        >Manager</b-form-radio
      >
    </b-form-radio-group>

    <b-button
      block
      @click="doInvite"
      :disabled="loading || (!email && !jabatan)"
      style="
            margin-top: 4%;
            background-color: #327ff2;
            font-family: 'nunito', sans-serif;
            font-weight: 700;
            border: none;
          "
    >
      <span v-if="!loading">Undang</span>
      <WhiteSpinner v-else />
    </b-button>
  </b-modal>
</template>

<script>
import axios from "@/ip_config.js";
import WhiteSpinner from "@/components/spinner/white-spinner.vue";

export default {
  components: { WhiteSpinner },
  data() {
    return {
      email: "",
      jabatan: "",
      loading: false,
    };
  },
  methods: {
    async doInvite() {
      let vm = this;
      vm.loading = true;

      try {
        let res = await axios.post(
          "poolKAP/invite",
          {
            email: vm.email,
            jabatan: vm.jabatan,
            KAPId: +localStorage.getItem("idKAP"),
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        );

        if (
          res.data.message == "tidak punya authorization" ||
          res.data.message == "anda tidak memiliki akses"
        ) {
          vm.$bvModal.hide("modalInviteUser");
          vm.$emit("alertFromChild", {
            pesan: "Anda Tidak Memiliki Akses",
            variant: "danger",
            show: true,
          });
        } else if (res.data.message == "email tidak terdaftar") {
          vm.$bvModal.hide("modalInviteUser");
          vm.$emit("alertFromChild", {
            pesan: "Email Tidak Terdaftar",
            variant: "danger",
            show: true,
          });
        } else if (res.data.message == "user tidak dapat di undang") {
          vm.$bvModal.hide("modalInviteUser");
          vm.$emit("alertFromChild", {
            pesan: "User tidak dapat diundang / user sudah ada bergabung",
            variant: "danger",
            show: true,
          });
        } else {
          vm.$bvModal.hide("modalInviteUser");
          vm.$emit("alertFromChild", {
            pesan: `Undangan Berhasil Dikirim ke ${vm.email}`,
            variant: "success",
            show: true,
          });
          vm.email = "";
          vm.jabatan = "";
        }
        vm.loading = false;
      } catch (error) {
        vm.loading = false;
        vm.$bvModal.hide("modalInviteUser");
        vm.$emit("alertFromChild", {
          pesan: "Koneksi ke Server Terganggu, Cobalah Beberapa Saat Lagi!",
          variant: "danger",
          show: true,
        });
      } finally {
      }
    },
  },
};
</script>

<style></style>
