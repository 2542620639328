<template>
  <div class="wrapper">
    <div class="button-wrapper">
      <div class="front">
        <span>Akun Terdaftar</span>
      </div>
    </div>

    <div class="table">
      <b-alert dismissible :variant="variant" fade :show="alert">
        {{ pesan }}
      </b-alert>
      <b-overlay :show="loading == true" rounded="sm">
        <b-table
          :aria-hidden="show ? 'true' : null"
          hover
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          show-empty
          striped
          :fields="fields"
          style="
            text-align: center;
            font-family: 'nunito', sans-serif;
            text-transform: capitalize;
          "
        >
          <template #cell(aksi)="data">
            <div
              style="
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-evenly;
              "
            >
              <b-button
                v-if="role == 'admin' && data.item.jabatan != 'Admin'"
                @click="hapus(data.item.idPool)"
                style="
                  background-color: #e0544e;
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 28px;
                  width: 28px;
                "
              >
                <img src="../../../../assets/icon/trash.svg" alt="" />
              </b-button>
            </div>
          </template>
        </b-table>
        <template #overlay>
          <div class="text-center">
            <b-spinner></b-spinner>
            <p>Mohon Tunggu</p>
          </div>
        </template>
      </b-overlay>
    </div>

    <div class="paginate" v-if="loading == false && items.length > 10">
      <b-pagination
        v-model="currentPage"
        :total-rows="items.length"
        style="width: 50%; font-family: 'nunito', sans-serif"
        :per-page="perPage"
        size="sm"
        align="fill"
      ></b-pagination>
    </div>

    <ModalDeleteAP :data="id" @alertFromChild="triggerAlert($event)" />

    <b-alert dismissible :variant="variant" fade :show="show">
      {{ pesan }}
    </b-alert>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import WhiteSpinner from "@/components/spinner/white-spinner.vue";
import ModalDeleteAP from "./ModalDeleteAP.vue";
export default {
  components: {
    WhiteSpinner,
    ModalDeleteAP,
  },
  data() {
    return {
      fields: [
        {
          key: "nomer",
          label: "No",
        },
        {
          key: "nama",
          label: "Nama",
        },
        {
          key: "jabatan",
          label: "Peran",
        },
        {
          key: "aksi",
          label: "Aksi",
        },
      ],
      items: [],
      loading: false,
      perPage: 10,
      currentPage: 1,
      role: "",
      alert: false,
      alert2: false,
      pesan: "Akun berhasil dihapus",
      password: "",
      show: false,
      variant: "",
      id: 0,
    };
  },
  mounted() {
    this.getData();
    this.role = localStorage.getItem("role");
  },
  methods: {
    alertFromChild(payload) {
      this.pesan = payload.pesan;
      this.variant = payload.variant;
      this.show = payload.show;
      if (this.show === true) {
        this.getData();
        setTimeout(() => {
          this.show = false;
        }, 3000);
      }
    },
    getData() {
      let vm = this;
      vm.loading = true;
      let y = localStorage.getItem("idKAP");
      axios
        .post(
          "KAP/usersByKAP",
          {
            id: y,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.data[0].idPool == null) {
            vm.items = [];
          } else {
            vm.items = res.data;
            vm.items.forEach((el, id) => {
              if (vm.items[id].jabatan == "adminKAP") {
                vm.items[id].jabatan = "Admin";
              }
            });
            res.data.forEach((element, index) => {
              let x = this.items[index];
              x.nomer = index + 1;
            });
          }
          setTimeout(() => {
            vm.loading = false;
          }, 1500);
        });
    },
    hapus(id) {
      this.id = id;
      if (this.id) {
        this.$bvModal.show("modalDeleteAP");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);
.wrapper {
  .button-wrapper {
    .front {
      font-family: "nunito", sans-serif;
      font-size: 28px;
      font-weight: 300;
      padding-bottom: 1%;
    }
  }
  .table {
    max-height: 450px;
    // height: 450px;
  }
  .paginate {
    transform: translateY(270px);
    position: sticky;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .table2 {
    width: 100%;
    font-family: "nunito", sans-serif;
    table {
      width: 100%;
      text-align: center;
      tr {
        border-top: 1px solid map-get($color, "gray-dark");
        border-bottom: 1px solid map-get($color, "gray-dark");
        th {
          padding: 2% 0;
        }
        td {
          padding: 1.5% 1.5%;
        }
      }
    }
  }
}
</style>
