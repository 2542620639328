<template>
  <div>
    <b-modal hide-footer hide-header centered size="lg" id="modalEdit">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="$bvModal.hide('modalEdit')"
          style="background-color: white; border: none"
          ><img
            src="../../../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Edit Perusahaan
        </span>
        <div class="form" style="width: 95%; margin-top: 4%">
          <b-form-group
            label="Nama Client"
            label-for="input-formatter"
            class="mb-0"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 20px;
              font-weight: 300;
            "
          >
            <b-form-input
              id="input-formatter"
              placeholder="Nama Client"
              style="border-radius: 10px"
              size="lg"
              type="text"
              v-model="client.namaClient"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Alamat Client"
            label-for="input-formatter"
            class="mb-0"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 20px;
              font-weight: 300;
            "
          >
            <b-form-input
              id="input-formatter"
              placeholder="Alamat Client"
              style="border-radius: 10px"
              size="lg"
              type="text"
              v-model="client.alamatClient"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="No HP Client"
            label-for="input-formatter"
            class="mb-0"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 20px;
              font-weight: 300;
            "
          >
            <b-form-input
              id="input-formatter"
              placeholder="No HP Client"
              style="border-radius: 10px"
              size="lg"
              type="text"
              v-model="client.noHpClient"
            >
            </b-form-input>
          </b-form-group>
        </div>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doEdit"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            >Edit Perusahaan</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
          >
            <WhiteSpinner />
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import WhiteSpinner from "@/components/spinner/white-spinner.vue";
export default {
  components: { WhiteSpinner },
  props: {
    client: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async doEdit() {
      let vm = this;
      vm.loading = true;
      try {
        await axios.post(
          "client/update/" + vm.client.clientid,
          {
            namaClient: vm.client.namaClient,
            alamatClient: vm.client.alamatClient,
            noHpClient: vm.client.noHpClient,
            id: vm.client.clientid,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        );
      } catch (error) {
        console.log(error);
        vm.$emit("alertFromChild", {
          show: true,
          pesan: `Mohon Coba Beberapa Saat Lagi`,
          variant: "danger",
        });
        vm.loading = false;
      } finally {
        vm.loading = false;
        vm.$bvModal.hide("modalEdit");
        vm.$emit("alertFromChild", {
          show: true,
          pesan: `Berhasil Mengubah Client ${vm.client.namaClient}`,
          variant: "success",
        });
        this.$store.dispatch("apiListClient/getData");
      }
    },
  },
};
</script>

<style></style>
