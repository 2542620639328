<template>
  <div>
    <section class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <nav_bar />

      <div class="top">
        <div
          style="
          width: 50%;
          height: 65px;
          display: flex;
          justify-content: flex-start;
          align-items:center
          background-color: yellow;
        "
        >
          <b-breadcrumb>
            <b-breadcrumb-item @click="goBack()">Dashboard</b-breadcrumb-item>
            <b-breadcrumb-item id="target1" active>
              <b-tooltip target="target1" triggers="hover">{{
                profileKAP.namaKAP
              }}</b-tooltip>
              {{ profileKAP.namaKAP1 }}</b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>

        <div
          style="
          width: 70%;
          height: 65px;
          display: flex;
          justify-content: flex-end;
          align-items:center
          background-color: yellow;
          padding-right:15px;
        "
        >
          <div class="button-wrapper">
            <b-button
              :class="{ tabActive: currentTab == 1 }"
              @click="selectTab(1)"
              style="margin-right: 0%"
              >profil KAP</b-button
            >
            <b-button
              :class="{ tabActive: currentTab == 2 }"
              @click="selectTab(2)"
              >daftar client</b-button
            >
          </div>
        </div>
      </div>

      <b-container fluid style="margin-top: 115px">
        <b-row style="padding: 0 1%">
          <b-col v-if="currentTab == 1" style="margin-top: 4%">
            <b-row>
              <b-col
                md="4"
                lg="4"
                xl="2"
                style="border-right: 1px solid #c8d5ec; position: sticky"
              >
                <profile_user_kap :currentTab="currentTab" />
              </b-col>
              <b-col
                md="8"
                lg="8"
                xl="5"
                style="border-right: 1px solid #c8d5ec; position: sticky"
              >
                <profile_kap />
              </b-col>
              <b-col xl="5" class="d-md-none d-lg-none d-xl-block">
                <DonutKAP />
              </b-col>

              <b-col
                md="12"
                lg="12"
                class="d-md-block d-lg-block d-xl-none"
                style="margin-top: 240px"
              >
                <DonutKAP />
              </b-col>
            </b-row>
          </b-col>
          <b-col v-else style="margin-top: 4%">
            <b-row>
              <b-col
                class="d-md-none d-lg-none d-xl-block"
                xl="2"
                style="border-right: 1px solid #c8d5ec"
              >
                <profile_user_kap :currentTab="currentTab" />
              </b-col>
              <b-col md="12" lg="12" xl="10">
                <TableClient />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="d-sm-block d-md-none d-lg-none d-xl-none">
      <b-container>
        <b-row>
          <b-col cols="12" sm="12">
            <div
              style="
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <h4 class="text-center">
                <strong>Does not support mobile version</strong>
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import WhiteSpinner from "@/components/spinner/white-spinner.vue";
import moment from "moment";
import TableClient from "./components/TableClient.vue";
import profile_kap from "./components/ProfileKAPMid.vue";
// import TableUserKAP from "./components/TableUserKAP.vue";
import profile_user_kap from "./components/ProfileKAPLeft.vue";
import { mapState } from "vuex";
import Nav_bar from "../../../components/nav_bar.vue";
import DonutKAP from "../../../components/chart/donutKAP.vue";
export default {
  components: {
    WhiteSpinner,
    TableClient,
    profile_user_kap,
    profile_kap,
    // TableUserKAP,
    Nav_bar,
    DonutKAP,
  },
  data() {
    return {
      kap: [],
      totalClient: 0,
      namaClient: "",
      alamatClient: "",
      noHpClient: "",
      clientid: "",
      currentTab: 1,
      loading: false,
      bcKAP: "",
      items: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Manage",
          href: "#",
        },
        {
          text: "Library",
          active: true,
        },
      ],
    };
  },
  beforeMount() {
    localStorage.setItem("idKAP", this.$route.params.id);
  },
  computed: {
    ...mapState("apiProfileKAP", ["profileKAP"]),
  },
  methods: {
    selectTab(selected) {
      let vm = this;
      vm.currentTab = selected;
    },
    getList() {
      moment.locale("id");
      let vm = this;
      let z = localStorage.getItem("token");
      axios
        .get("KAP/listById/" + this.$route.params.id, {
          headers: {
            token: z,
          },
        })
        .then((res) => {
          res.data.respon.forEach((el, id) => {
            if (el[id] == null) {
              vm.totalClient = 0;
            } else {
              vm.totalClient += 1;
            }
          });
          vm.kap = res.data.respon[0];
          if (vm.kap.jabatan == "adminKAP") {
            vm.kap.jabatan = "admin";
          }
          res.data.respon.forEach((el) => {
            vm.kap.tanggalkap = moment(el.tanggalkap).format("LL");
          });
        });
    },
    async goClient(clientid) {
      this.$router.push("/viewclient/" + clientid);
    },
    goBack() {
      this.$router.push("/main_dashboard");
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);
.container-fluid {
  min-height: 100vh;
  background-color: map-get($color, "white");
}
.bg {
  position: fixed;
  height: 20%;
  width: 100%;
  background-color: #327ff2;
}
.top {
  width: 96%;
  height: 65px;
  background-color: map-get($color, "white2");
  box-shadow: 0px 10px 35px -10px rgba(123, 156, 160, 0.1);
  border-radius: 10px;
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  display: flex;
  .breadcrumb {
    font-weight: 700;
    font-family: "nunito", sans-serif;
    text-transform: capitalize;
    transform: translateY(16%);
    background-color: transparent;
    color: map-get($color, "blue");
    width: 100%;
    // background-color: red;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    .btn {
      font-family: "nunito", sans-serif;
      color: map-get($color, "blue");
      font-weight: 600;
      background-color: map-get($color, "white");
      border: 2px solid #327ff2;
      text-transform: capitalize;
      border-radius: 10px;
      margin: 0 2%;
      padding: 0.5% 2%;
    }
    .tabActive {
      font-family: "nunito", sans-serif;
      color: map-get($color, "white");
      font-weight: 600;
      background-color: map-get($color, "blue");
      border: 2px solid #fff;
      text-transform: capitalize;
      box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
      border-radius: 10px;
      margin: 0 2%;
      padding: 0.5% 2%;
      border: 2px solid map-get($color, "blue");
    }
  }
}
.first-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .top {
    display: flex;
    width: 95%;
    align-items: center;
    background-color: map-get($color, "white2");
    box-shadow: 0px 10px 35px -10px rgba(123, 156, 160, 0.1);
    border-radius: 10px;
    transform: translateY(16%);
    justify-content: space-between;
    position: fixed;
    z-index: 2;
    .breadcrumb {
      font-weight: 700;
      font-family: "nunito", sans-serif;
      text-transform: capitalize;
      transform: translateY(16%);
      background-color: transparent;
      color: map-get($color, "blue");
    }
    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 50%;
      .btn {
        font-family: "nunito", sans-serif;
        color: map-get($color, "blue");
        font-weight: 700;
        background-color: map-get($color, "white");
        border: 2px solid #327ff2;
        text-transform: capitalize;
        border-radius: 10px;
        margin: 0 2%;
        padding: 0.5% 2%;
        height: 40px;
      }
      .tabActive {
        font-family: "nunito", sans-serif;
        color: map-get($color, "white");
        font-weight: 700;
        background-color: map-get($color, "blue");
        border: 2px solid #fff;
        text-transform: capitalize;
        box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
        border-radius: 10px;
        height: 40px;
        margin: 0 2%;
        padding: 0.5% 2%;
        border: 2px solid map-get($color, "blue");
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    margin-top: 2%;
    width: 100%;
    justify-content: space-evenly;
    .left {
      font-family: "nunito", sans-serif;
      font-size: 16px;
      font-weight: 300;
      color: map-get($color, "black");
      .role {
        background-color: #eafffa;
        font-weight: 700;
        color: #20bd95;
        padding: 2px 10px;
        text-transform: capitalize;
        border-radius: 50px;
      }
    }
    .mid {
      font-family: "nunito", sans-serif;
      font-size: 16px;
      font-weight: 300;
      color: map-get($color, "black");
      text-transform: capitalize;
      .total {
        font-weight: 700;
        padding: 2px 10px;
        background-color: #eeeeee;
        border-radius: 50px;
      }
    }
    .right {
      font-family: "nunito", sans-serif;
      font-size: 16px;
      font-weight: 300;
      color: #b5bac3;
      text-transform: capitalize;
    }
    .button {
      .btn {
        background-color: map-get($color, "blue");
        border: none;
        font-family: "nunito", sans-serif;
        font-weight: 700;
        display: flex;
        align-items: center;
        border-radius: 50px;
        text-transform: capitalize;
        img {
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
