<template>
  <div>
    <b-modal
      class="hapus"
      hide-footer
      hide-header
      centered
      id="modalDeleteAP"
      size="m"
    >
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Apakah anda yakin menghapus?
        </span>
      </div>
      <div style="margin-top: 4%">
        <b-form-group
          label-size="lg"
          label-cols="4"
          label-cols-lg="3"
          label="Password"
          label-for="input-formatter"
        >
          <b-form-input
            id="input-formatter"
            placeholder="Masukan Password"
            style="border-radius: 10px; margin-bottom: 15px"
            size="lg"
            type="password"
            v-model="password"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doDelete"
            size="sm"
            :disabled="!password || loading"
            style="
              background-color: #327ff2;
              margin-right: 10px;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
          >
            <span v-if="!loading">Ya</span>
            <WhiteSpinner v-else />
          </b-button>
          <b-button
            @click="$bvModal.hide('modalDeleteAP')"
            size="sm"
            :disabled="loading"
            style="
              background-color: #e0544e;
              margin-right: 10px;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
          >
            <span v-if="!loading">Tidak</span>
            <WhiteSpinner v-else />
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import WhiteSpinner from "@/components/spinner/white-spinner.vue";
export default {
  components: {
    WhiteSpinner,
  },
  props: {
    data: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      password: "",
    };
  },

  methods: {
    async doDelete() {
      let vm = this;
      vm.loading = true;

      try {
        await axios.post(
          "poolKAP/delete",
          { KAPId: vm.$route.params.id, id: ids },
          {
            headers: { token: localStorage.getItem("token") },
          }
        );
      } catch (error) {
        this.loading = false;
        vm.$emit("alertFromChild", {
          pesan: "Terjadi Gangguan Pada Koneksi Ke Server",
          variant: "success",
          show: true,
        });
      } finally {
        vm.$bvModal.hide("modalDeleteAP");
        vm.loading = false;
        vm.$emit("alertFromChild", {
          pesan: "Akun berhasil dihapus",
          variant: "success",
          show: true,
        });
        vm.password = "";
      }
    },
  },
};
</script>

<style></style>
