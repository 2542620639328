<template>
  <div class="wrapper">
    <div class="text">
      <div class="title">
        <span
          v-b-tooltip.hover
          :title="profileKAP.namaKAP"
          v-if="loadingK == false"
          >{{ profileKAP.namaKAP1 }}</span
        >
        <b-skeleton
          v-else
          class="skeleton-loading"
          animation="throb"
        ></b-skeleton>
      </div>
      <!-- <div class="title" v-else></div> -->

      <div class="join">
        <span v-if="loadingK == false">
          Joined on {{ profileKAP.tanggalkap }}</span
        >
        <b-skeleton
          v-else
          animation="throb"
          class="skeleton-loading"
        ></b-skeleton>
      </div>

      <div v-if="role != ''">
        <div class="peran">peran anda</div>
        <div class="admin" v-if="role == 'admin'">
          <span v-if="loadingK == false">{{ role }}</span>
          <b-skeleton
            v-else
            class="skeleton-loading"
            animation="throb"
          ></b-skeleton>
        </div>

        <div class="mng" v-else-if="role == 'manager'">
          <span v-if="loadingK == false">{{ role }}</span>
          <b-skeleton
            v-else
            class="skeleton-loading"
            animation="throb"
          ></b-skeleton>
        </div>

        <div class="spv" v-else-if="roleLocal == 'supervisor'">
          <span v-if="loadingK == false">{{ roleLocal }}</span>
          <b-skeleton
            v-else
            class="skeleton-loading"
            animation="throb"
          ></b-skeleton>
        </div>

        <div class="auditor" v-else-if="roleLocal == 'auditor'">
          <span v-if="loadingK == false">{{ roleLocal }}</span>
          <b-skeleton
            v-else
            class="skeleton-loading"
            animation="throb"
          ></b-skeleton>
        </div>

        <div class="jumlah">jumlah client</div>
        <div class="total">
          <span v-if="loadingK == false">{{ clientLength }} client</span>
          <b-skeleton
            v-else
            class="skeleton-loading"
            animation="throb"
          ></b-skeleton>
        </div>

        <div class="jumlah">jumlah user</div>
        <div class="total">
          <span v-if="loadingK == false">{{ user }} akun</span>
          <b-skeleton
            v-else
            class="skeleton-loading"
            animation="throb"
          ></b-skeleton>
        </div>
        <div class="buttons">
          <b-button pill @click="openLog">Lihat Log</b-button>
        </div>
      </div>
    </div>
    <ModalLogs :logs="logs" />
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import whiteSpinner from "@/components/spinner/white-spinner.vue";
import BlueSpinner from "@/components/spinner/blue-spinner.vue";
import { mapState } from "vuex";
import ModalLogs from "./ModalLogs.vue";
import moment from "moment";
moment.locale("id");
export default {
  components: { whiteSpinner, BlueSpinner, ModalLogs },
  props: ["currentTab"],
  data() {
    return {
      namaClient: "",
      alamatClient: "",
      noHpClient: "",
      id: "",
      jabatan: "",
      loading: true,
      loading2: false,
      loading3: false,
      loading4: false,
      loadingModal: false,
      logs: [],
      pesan: "",
      roleLocal: "",
    };
  },
  computed: {
    ...mapState("apiUser", ["stateId"]),
    ...mapState("apiProfileKAP", [
      "profileKAP",
      "clientLength",
      "loadingK",
      "user",
      "role",
    ]),
    ...mapState("apiListClient", ["loadingT"]),
  },
  mounted() {
    this.$store.dispatch(
      "apiProfileKAP/getDetails",
      localStorage.getItem("idKAP")
    );
    this.$store.dispatch("apiListClient/getData");
    this.roleLocal = localStorage.getItem("role");
  },
  methods: {
    async openLog() {
      let vm = this;
      vm.loadingModal = true;
      try {
        let fetch = await axios.get("log/listByKAP/" + this.$route.params.id, {
          headers: {
            token: localStorage.getItem("token"),
          },
        });
        for (const item of fetch.data) {
          item.createdAt = moment(item.createdAt).format("LLL");
        }
        vm.logs = fetch.data;
      } catch (error) {
        console.log(error);
      } finally {
        vm.loadingModal = false;
        vm.$bvModal.show("modalLog");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);
.wrapper {
  display: flex;
  flex-direction: column;
  .text {
    height: 500px;
    .title {
      font-weight: 700;
      color: map-get($color, "black");
      font-size: 28px;
      font-family: "nunito", sans-serif;
      text-transform: capitalize;
      .skeleton-loading {
        height: 30px;
      }
    }
    .join {
      font-weight: 300;
      color: map-get($color, "gray-dark");
      font-family: "nunito", sans-serif;
      font-size: 14px;
      .skeleton-loading {
        height: 14px;
        margin-top: 4%;
      }
    }
    .peran {
      font-weight: 300;
      color: map-get($color, "black");
      font-family: "nunito", sans-serif;
      font-size: 16px;
      margin-top: 8%;
      text-transform: capitalize;
    }
    .admin {
      margin-top: 2%;
      span {
        background-color: #eafffa;
        font-family: "nunito", sans-serif;
        border-radius: 50px;
        color: #20bd95;
        text-transform: capitalize;
        border: 2px solid #20bd95;
        padding: 0.5% 5%;
        font-weight: 600;
      }
      .skeleton-loading {
        width: 50%;
        height: 16px;
      }
    }
    .spv {
      margin-top: 2%;
      span {
        background-color: #ffefdb;
        font-family: "nunito", sans-serif;
        padding: 0.5% 5%;
        font-weight: 600;
        border-radius: 50px;
        border: 2px solid #ffac4b;
        color: #ffac4b;
        text-transform: capitalize;
      }
      .skeleton-loading {
        width: 50%;
        height: 16px;
      }
    }
    .mng {
      margin-top: 2%;
      span {
        background-color: #f4f3ff;
        font-family: "nunito", sans-serif;
        padding: 0.5% 5%;
        font-weight: 600;
        border-radius: 50px;
        border: 2px solid #5c4af5;
        color: #5c4af5;
        text-transform: capitalize;
      }
      .skeleton-loading {
        width: 50%;
        height: 16px;
      }
    }
    .auditor {
      margin-top: 2%;
      span {
        background-color: #ffe8e7;
        font-family: "nunito", sans-serif;
        padding: 0.5% 5%;
        font-weight: 600;
        border-radius: 50px;
        border: 2px solid #e0544e;
        color: #e0544e;
        text-transform: capitalize;
      }
      .skeleton-loading {
        width: 50%;
        height: 16px;
      }
    }
    .jumlah {
      margin-top: 8%;
      font-family: "nunito", sans-serif;
      text-transform: capitalize;
    }
    .total {
      margin-top: 4%;
      span {
        background-color: rgba(238, 238, 238, 0.5);
        font-weight: 700;
        font-family: "nunito", sans-serif;
        text-transform: capitalize;
        color: map-get($color, "black");
        padding: 2% 6%;
        border-radius: 50px;
      }
      .skeleton-loading {
        width: 50%;
        height: 16px;
      }
    }
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    .btn {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      font-family: "nunito", sans-serif;
      box-shadow: 0px 20px 25px -10px rgba(15, 93, 210, 0.25);
      height: 57px;
      border: none !important;
      background-color: map-get($color, "blue");
      font-family: "nunito", sans-serif;
      font-weight: 700;
      text-transform: capitalize;
    }
  }
  .buttons {
    margin-top: 4%;
    .btn {
      font-weight: 500;
      background-color: map-get($color, "blue");
      border: none;
      font-size: 14px;
    }
  }
}
</style>
