<template>
  <div class="list">
    <div style="display: flex; width: 100%" class="mb-3">
      <div class="inner-btn d-md-block d-lg-block d-xl-none">
        <b-button
          class="refresh"
          @click="() => this.$store.dispatch('apiListClient/getData')"
          v-if="loadingT == false"
        >
          <img src="../../../../assets/icon/icon/rotate-cw.svg" alt="" />
        </b-button>
        <b-button disabled v-else>
          <img src="../../../../assets/icon/icon/rotate-cw.svg" alt="" />
        </b-button>
      </div>

      <div class="inner-btn d-md-block d-lg-block d-xl-none">
        <b-button
          class="client"
          v-if="
            (rolex == 'manager' && loadingT == false) ||
              (rolex == 'admin' && loadingT == false)
          "
          @click="openModal2"
        >
          <img src="../../../../assets/icon/icon/plus-circle.svg" alt="" />
          <div class="text">tambah client</div>
        </b-button>
      </div>

      <div class="inner-btn d-md-block d-lg-block d-xl-none">
        <b-button
          v-if="
            (rolex == 'manager' && loadingT == false) ||
              (rolex == 'admin' && loadingT == false)
          "
          class="invite"
          @click="$bvModal.show('modalInviteUser')"
        >
          <img src="../../../../assets/icon/icon/mail.svg" alt="" />
          <div class="text">undang akun</div></b-button
        >
      </div>
    </div>
    <div class="button-wrapper">
      <div class="front">
        <span>Daftar Client</span>
        <div class="inner-btn d-md-none d-lg-none d-xl-block">
          <b-button
            class="refresh"
            @click="() => this.$store.dispatch('apiListClient/getData')"
            v-if="loadingT == false"
          >
            <img src="../../../../assets/icon/icon/rotate-cw.svg" alt="" />
          </b-button>
          <b-button disabled v-else>
            <img src="../../../../assets/icon/icon/rotate-cw.svg" alt="" />
          </b-button>
        </div>

        <div class="inner-btn d-md-none d-lg-none d-xl-block">
          <b-button
            class="client"
            v-if="
              (rolex == 'manager' && loadingT == false) ||
                (rolex == 'admin' && loadingT == false)
            "
            @click="openModal2"
          >
            <img src="../../../../assets/icon/icon/plus-circle.svg" alt="" />
            <div class="text">tambah client</div>
          </b-button>
        </div>

        <div class="inner-btn d-md-none d-lg-none d-xl-block">
          <b-button
            v-if="
              (rolex == 'manager' && loadingT == false) ||
                (rolex == 'admin' && loadingT == false)
            "
            class="invite"
            @click="$bvModal.show('modalInviteUser')"
          >
            <img src="../../../../assets/icon/icon/mail.svg" alt="" />
            <div class="text">undang akun</div></b-button
          >
        </div>
      </div>

      <b-modal hide-footer hide-header centered ref="modal-add" size="lg">
        <div class="tutup" style="display: flex; justify-content: flex-end">
          <b-button
            @click="tutupModal1"
            style="background-color: white; border: none"
            ><img
              src="../../../../assets/icon/close.svg"
              alt=""
              style="width: 15px; height: 15px"
          /></b-button>
        </div>
        <div
          class="content"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 1% 2%;
          "
        >
          <span
            class="tittle"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 28px;
              font-weight: 700;
              text-transform: capitalize;
            "
          >
            Tambah Client
          </span>
          <div class="form" style="width: 95%; margin-top: 4%">
            <b-form-group
              label="Nama Client"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="Nama Client"
                style="border-radius: 10px; margin: 2% 0"
                size="lg"
                type="text"
                v-model="namaClient"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Alamat Client"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="Alamat Client"
                style="border-radius: 10px; margin: 2% 0"
                size="lg"
                type="text"
                v-model="alamatClient"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="No HP Client"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="No HP Client"
                style="border-radius: 10px; margin: 2% 0"
                size="lg"
                type="text"
                v-model="noHpClient"
              ></b-form-input>
              <div class="button-wrapper" style="margin-top: 8%">
                <b-button
                  style="
                    background-color: #327ff2;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    color: #fff;
                    border: none;
                    height: 57px;
                    border-radius: 10px;
                  "
                  block
                  @click="doAdd"
                  v-if="
                    loading2 == false && namaClient != '' && noHpClient != ''
                  "
                  >Tambah Client</b-button
                >
                <b-button
                  style="
                    background-color: #327ff2;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    color: #fff;
                    border: none;
                    height: 57px;
                    border-radius: 10px;
                  "
                  block
                  v-else-if="loading2 == true"
                  disabled
                  ><white-spinner />
                </b-button>

                <b-button
                  style="
                    background-color: #327ff2;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    color: #fff;
                    border: none;
                    height: 57px;
                    border-radius: 10px;
                  "
                  block
                  disabled
                  v-else
                  >Tambah Client</b-button
                >
              </div>
            </b-form-group>
          </div>
        </div>
      </b-modal>

      <div class="search-box">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              style="
                border-radius: 10px;
                font-family: 'nunito', sans-serif;
                border-radius: 7px;
              "
              type="text"
              placeholder="Masukan Kata Kunci"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
    </div>

    <div class="client">
      <b-overlay :show="loadingT == true" rounded="sm">
        <b-table
          :aria-hidden="show ? 'true' : null"
          hover
          striped
          :filter="filter"
          :items="listClient"
          :fields="fields"
          show-empty
          :current-page="currentPage"
          :per-page="perPage"
          style="
            text-align: center;
            font-family: 'nunito', sans-serif;
            text-transform: capitalize;
          "
        >
          <template #cell(namaClient)="data">
            <a
              style="cursor: pointer; color: #327ff2; font-weight: 600"
              @click="goClient(data.item.clientid)"
              @click.right="goClientInNewTab(data.item.clientid)"
              @click.middle="goClientInNewTab(data.item.clientid)"
              @contextmenu.prevent
              >{{ data.value }}</a
            >
          </template>
          <template #cell(aksi)="data">
            <div
              class="buttons"
              style="
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-evenly;
              "
            >
              <b-button
                @click="editClient(data.item.clientid)"
                style="
                  background-color: #f4b366;
                  border: none;
                  height: 28px;
                  justify-content: center;
                  width: 28px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img src="../../../../assets/icon/edit-2.svg" alt="" />
              </b-button>
              <b-button
                variant="info"
                v-if="rolex == 'admin' && data.item.clientDeletedAt == null"
                @click="arsip(data.item)"
                style="
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 28px;
                  width: 28px;
                "
              >
                <img
                  src="../../../../assets/icon/archive.svg"
                  style="height: 19px"
                  alt=""
                />
              </b-button>
              <b-button
                variant="success"
                v-if="rolex == 'admin' && data.item.clientDeletedAt != null"
                @click="restore(data.item.clientid)"
                style="
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 28px;
                  width: 28px;
                "
              >
                <img
                  src="../../../../assets/icon/arrow-counterclockwise.svg"
                  alt=""
                />
              </b-button>
              <b-button
                v-if="rolex == 'admin'"
                @click="destroy(data.item.clientid)"
                style="
                  background-color: #e0544e;
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 28px;
                  width: 28px;
                "
              >
                <img src="../../../../assets/icon/trash.svg" alt="" />
              </b-button>
            </div>
          </template>
        </b-table>
        <template #overlay>
          <div class="text-center">
            <b-spinner></b-spinner>
            <p>Mohon Tunggu</p>
          </div>
        </template>
      </b-overlay>
    </div>

    <div class="paginate">
      <b-pagination
        v-model="currentPage"
        :total-rows="listClient.length"
        style="width: 50%; font-family: 'nunito', sans-serif"
        :per-page="perPage"
        align="fill"
        size="sm"
      ></b-pagination>
    </div>

    <div class="alert">
      <div>
        <!-- <b-alert
          dismissible
          style="width: 50%"
          variant="danger"
          fade
          :show="alert"
        >
          {{ pesan }}
        </b-alert>
        <b-alert
          dismissible
          style="width: 50%"
          variant="success"
          fade
          :show="alert2"
        >
          {{ pesan }}
        </b-alert> -->

        <b-alert
          dismissible
          style="width: 50%"
          :variant="variant"
          fade
          :show="show"
        >
          {{ pesan }}
        </b-alert>
      </div>
    </div>

    <ModalEdit :client="client" @alertFromChild="triggerAlert($event)" />
    <ModalInviteUser @alertFromChild="triggerAlert($event)" />
    <ModalDeleteClient :data="client" @alertFromChild="triggerAlert($event)" />

    <b-modal centered ref="tidak-aktif" hide-footer title="PERHATIAN!">
      <div style="padding: 5%; font-size: 19px">
        {{ msg }}
      </div>
      <center>
        <b-button style="background-color: #327ff2; border: none" @click="close"
          >Tutup</b-button
        >
      </center>
    </b-modal>

    <!-- modal hapus client -->

    <b-modal
      class="destroy"
      hide-footer
      hide-header
      centered
      ref="modal-destroy"
      size="m"
    >
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Apakah anda yakin menghapus client?
        </span>
      </div>
      <div style="margin-top: 4%">
        <b-form-group
          label-size="lg"
          label-cols="4"
          label-cols-lg="3"
          label="Password"
          label-for="input-formatter"
        >
          <b-form-input
            id="input-formatter"
            placeholder="Masukan Password"
            style="border-radius: 10px; margin-bottom: 15px"
            size="lg"
            type="password"
            v-model="password"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doDestroy()"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Ya</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            ><white-spinner
          /></b-button>
          <b-button
            @click="batalDestroy()"
            v-if="loading == false"
            variant="danger"
            style="
              margin-left: 10px;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Tidak</b-button
          >
          <b-button
            disabled
            variant="danger"
            v-else
            style="
              margin-left: 10px;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      class="restore"
      hide-footer
      hide-header
      centered
      ref="modal-aktif"
      size="m"
    >
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Apakah anda yakin ingin mengaktifkan client?
        </span>
      </div>
      <div style="margin-top: 4%">
        <b-form-group
          label-size="lg"
          label-cols="4"
          label-cols-lg="3"
          label="Password"
          label-for="input-formatter"
        >
          <b-form-input
            id="input-formatter"
            placeholder="Masukan Password"
            style="border-radius: 10px; margin-bottom: 15px"
            size="lg"
            type="password"
            v-model="password"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doRestore()"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Ya</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            ><white-spinner
          /></b-button>
          <b-button
            @click="closeRestore()"
            v-if="loading == false"
            variant="danger"
            style="
              border: none;
              margin-left: 10px;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Tidak</b-button
          >
          <b-button
            disabled
            v-else
            variant="danger"
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import WhiteSpinner from "@/components/spinner/white-spinner.vue";
import ModalEdit from "./ModalEdit.vue";
import ModalInviteUser from "./ModalInviteUser.vue";
import ModalDeleteClient from "./ModalDeleteClient.vue";
export default {
  components: {
    WhiteSpinner,
    Multiselect,
    ModalEdit,
    ModalInviteUser,
    ModalDeleteClient,
  },

  data() {
    return {
      kap: [],
      namaClient: "",
      loadingt: false,
      loading: false,
      // loadingT: false,
      pesan: "",
      password: "",
      alert: false,
      alert2: false,
      alamatClient: "",
      noHpClient: "",
      clientid: "",
      currentPage: 1,
      items: [],
      options: [],
      client: {},
      pesan: "",
      filter: null,
      perPage: 10,
      loading2: false,
      rolex: "",
      show: false,
      value: "",
      msg: "",
      variant: "",
      fields: [
        {
          key: "nomer",
          label: "No",
        },
        {
          key: "namaClient",
          label: "Nama Perusahaan",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "lastaudit",
          label: "Terakhir Audit",
        },
        {
          key: "clientUpdate",
          label: "Terakhir Diubah",
        },
        {
          key: "aksi",
          label: "Aksi",
        },
      ],
      client: {},
    };
  },

  beforeMount() {
    this.$store.dispatch("apiUser/getUser");
  },

  computed: {
    ...mapState("apiListClient", ["listClient", "loadingT"]),
    ...mapState("apiUser", ["stateId"]),
    // ...mapState("apiClient", ["tidakAktif"]),
  },

  mounted() {
    this.rolex = localStorage.getItem("role");
  },

  methods: {
    triggerAlert(payload) {
      this.pesan = payload.pesan;
      this.variant = payload.variant;
      this.show = true;

      setTimeout(() => {
        this.show = false;
      }, 3000);
    },
    async goClient(clientid) {
      this.$store.commit("loadingT", true);
      localStorage.setItem("idClient", clientid);
      axios
        .get("client/listById/" + clientid, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.data.message === "CLIENT TIDAK AKTIF") {
            this.msg = res.data.message;
            this.$refs["tidak-aktif"].show();
          } else if (res.data.jabatan === "tidak ada") {
            this.msg = "ANDA BELUM APPROVAL CLIENT CLIENT INI";
            this.$refs["tidak-aktif"].show();
          } else if (res.data.jabatan) {
            this.$router.push("/profile_client/" + clientid);
          }
        });
      this.$store.commit("loadingT", false);
    },

    async goClientInNewTab(clientid) {
      this.$store.commit("loadingT", true);
      localStorage.setItem("idClient", clientid);
      axios
        .get("client/listById/" + clientid, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.data.message === "CLIENT TIDAK AKTIF") {
            this.msg = res.data.message;
            this.$refs["tidak-aktif"].show();
          } else if (res.data.jabatan === "tidak ada") {
            this.msg = "ANDA BELUM APPROVAL CLIENT CLIENT INI";
            this.$refs["tidak-aktif"].show();
          } else if (res.data.jabatan) {
            //this.$router.push("/profile_client/" + clientid);
            window.open("https://online.kapmobile.id/#/profile_client/" + clientid, '');
          }
        });
      this.$store.commit("loadingT", false);
    },

    close() {
      this.$refs["tidak-aktif"].hide();
    },

    async editClient(id) {
      let vm = this;
      try {
        let res = await axios.get("client/listById/" + id, {
          headers: { token: localStorage.getItem("token") },
        });
        vm.client = res.data.data[0];
      } catch (error) {
        console.log(error);
      } finally {
        vm.$bvModal.show("modalEdit");
      }
    },

    openModal2() {
      this.$refs["modal-add"].show();
    },

    tutupModal1() {
      this.$refs["modal-add"].hide();
      this.$refs["modal-edit"].hide();
    },

    doAdd() {
      let vm = this;
      vm.loading2 = true;

      axios
        .post(
          "client/register",
          {
            namaClient: vm.namaClient,
            alamatClient: vm.alamatClient,
            noHpClient: vm.noHpClient,
            KAPId: this.$route.params.id,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.message == "client sudah maksimal") {
            vm.pesan = "Jumlah client sudah maksimal";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (res.data.message == "data sudah ada") {
            vm.pesan = "Data client sudah ada";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else {
            vm.pesan = "Berhasil mendaftarkan client";
            vm.alert2 = true;
            setTimeout(() => {
              vm.alert2 = false;
            }, 3000);
            this.$store.dispatch("apiListClient/getData");
          }
          vm.loading2 = false;
          vm.namaClient = "";
          vm.alamatClient = "";
          vm.noHpClient = "";
          this.$refs["modal-add"].hide();
        });
    },

    arsip(id) {
      console.log(id);
      this.client = id;
      if (this.client) {
        this.$bvModal.show("modalDeleteClient");
      }
    },

    batalDelete() {
      this.$refs["modal-hapus"].hide();
    },

    destroy(iid) {
      this.id = iid;
      this.$refs["modal-destroy"].show();
    },

    batalDestroy() {
      this.$refs["modal-destroy"].hide();
    },

    doDestroy() {
      let vm = this;
      let idx = vm.id;
      this.loading = true;

      axios
        .post(
          "client/destroyClient",
          {
            clientId: idx,
            password: vm.password,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.message == "password Salah") {
            this.$refs["modal-destroy"].hide();
            vm.loading = false;
            vm.password = "";
            vm.pesan = "password salah";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (vm.password == "") {
            this.$refs["modal-destroy"].hide();
            this.loading = false;
            vm.pesan = "masukan password";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else {
            this.$refs["modal-destroy"].hide();
            this.loading = false;
            vm.password = "";
            vm.pesan = "client berhasil dihapus";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
            this.$store.dispatch("apiListClient/getData");
          }
        });
    },

    restore(iii) {
      this.id = iii;
      this.$refs["modal-aktif"].show();
    },

    closeRestore() {
      this.$refs["modal-aktif"].hide();
    },

    doRestore() {
      let vm = this;
      let id = vm.id;
      let y = localStorage.getItem("idKAP");
      this.loading = true;

      axios
        .post(
          "client/restoreClient",
          {
            clientId: id,
            password: vm.password,
            KAPId: y,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.message == "password Salah") {
            this.$refs["modal-aktif"].hide();
            this.loading = false;
            vm.password = "";
            vm.pesan = "password salah";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (vm.password == "") {
            this.$refs["modal-aktif"].hide();
            this.loading = false;
            vm.pesan = "masukan password";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (res.data.message == "client Sudah Maksimal") {
            this.$refs["modal-aktif"].hide();
            this.loading = false;
            vm.password = "";
            vm.pesan = "Client Sudah Maksimal";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else {
            this.$refs["modal-aktif"].hide();
            this.loading = false;
            vm.password = "";
            vm.pesan = "Client berhasil diaktifkan kembali";
            vm.alert2 = true;
            setTimeout(() => {
              vm.alert2 = false;
            }, 3000);
            this.$store.dispatch("apiListClient/getData");
          }
        });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);
.inner-btn {
  margin: 0 2%;
  .refresh {
    height: 28px;
    display: flex;
    align-items: center;
    background-color: map-get($color, "blue");
    border: none;
  }
  .client {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: map-get($color, "blue");
    font-family: "nunito", sans-serif;
    text-transform: capitalize;
    border: none;
    .text {
      font-size: 14px;
    }
    img {
      width: 14%;
    }
  }
  .invite {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: map-get($color, "blue");
    font-family: "nunito", sans-serif;
    text-transform: capitalize;
    border: none;
    .text {
      font-size: 14px;
    }
    img {
      width: 14%;
    }
  }
}
.list {
  .button-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .front {
      display: flex;
      align-items: center;
      width: 60%;
      font-family: "nunito", sans-serif;
      span {
        color: map-get($color, "black");
        font-size: 28px;
      }
      .inner-btn {
        margin: 0 2%;
        .refresh {
          height: 28px;
          display: flex;
          align-items: center;
          background-color: map-get($color, "blue");
          border: none;
        }
        .client {
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: map-get($color, "blue");
          font-family: "nunito", sans-serif;
          text-transform: capitalize;
          border: none;
          .text {
            font-size: 14px;
          }
          img {
            width: 14%;
          }
        }
        .invite {
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: map-get($color, "blue");
          font-family: "nunito", sans-serif;
          text-transform: capitalize;
          border: none;
          .text {
            font-size: 14px;
          }
          img {
            width: 14%;
          }
        }
      }
    }
    .search-box {
      width: 40%;
    }
  }
  .paginate {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .client2 {
    width: 100%;
    font-family: "nunito", sans-serif;
    table {
      width: 100%;
      text-align: center;
      tr {
        border-top: 1px solid map-get($color, "gray-dark");
        border-bottom: 1px solid map-get($color, "gray-dark");
        th {
          padding: 2% 0;
        }
        td {
          padding: 1.5% 1.5%;
        }
      }
    }
  }

  .alert {
    width: 70%;
    z-index: 10;
    height: 50px;
    position: fixed;
    transform: translate(15%, 0%);
  }
}
</style>
