<template>
  <div class="wrapper">
    <div class="donut">
      <div class="judul">project On progress</div>
      <div class="angka">
        <div class="persen">{{ val }}</div>
        <div class="upah">{{ valLabels }}</div>
      </div>
      <apexchart
        type="donut"
        :series="series"
        :width="450"
        :options="chartOptions"
      ></apexchart>
      <div class="title">
        <div class="legend">
          <div
            class="ininya"
            style="
              color: #20bd95;
              border: 2px solid #20bd95;
              background-color: #eafffa;
            "
          >
            Just Started: <span>{{ series[0] }}</span>
          </div>
          <div
            class="ininya"
            style="
              color: #5c4af5;
              border: 2px solid #5c4af5;
              background-color: #f4f3ff;
            "
          >
            Mid Proccess: <span>{{ series[1] }}</span>
          </div>
          <div
            class="ininya"
            style="
              color: #ffac4b;
              background-color: #ffefdb;
              border: 2px solid #ffac4b;
            "
          >
            Almost Deadline: <span>{{ series[2] }}</span>
          </div>
          <div
            class="ininya"
            style="
              color: #e0544e;
              background-color: #ffe8e7;
              border: 2px solid #e0544e;
            "
          >
            Over Deadline: <span>{{ series[3] }}</span>
          </div>
          <div
            class="ininya"
            style="
              color: #696a6d;
              border: 2px solid #696a6d;
              background-color: #e9e9e9;
            "
          >
            Canceled: <span>{{ series[4] }}</span>
          </div>
          <div
            class="ininya"
            style="
              color: #327ff2;
              border: 2px solid #327ff2;
              background-color: #e6eefc;
            "
          >
            Finish: <span>{{ series[5] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "@/ip_config";

export default {
  components: { apexcharts: VueApexCharts },
  data() {
    return {
      theChart: [],
      val: "",
      valLabels: "",
      series: [0, 0, 0, 0, 0, 0],
      chartOptions: {
        dataLabels: {
          style: {
            colors: ["#061737"],
          },
          dropShadow: {
            enabled: false,
          },
        },
        labels: [
          "Just Started",
          "Middle Process",
          "Almost Deadline",
          "Over Deadline",
          "Canceled",
          "Done",
        ],
        colors: [
          "#6ADBBE",
          "#5C4AF5",
          "#F4B366",
          "#E0544E",
          "#696a6d",
          "#327ff2",
        ],
        chart: {
          id: "charts",
          type: "donut",
          events: {
            dataPointSelection: (a, b, config) => {
              let vm = this;
              if (config.dataPointIndex == 0) {
                localStorage.setItem("idKAP", vm.$route.params.id);
                vm.$router.push("/detail_donut/" + 0);
                vm.val = vm.series[0];
                vm.valLabels = vm.chartOptions.labels[0];
              } else if (config.dataPointIndex == 1) {
                localStorage.setItem("idKAP", vm.$route.params.id);
                vm.$router.push("/detail_donut/" + 1);
                vm.val = vm.series[1];
                vm.valLabels = vm.chartOptions.labels[1];
              } else if (config.dataPointIndex == 2) {
                localStorage.setItem("idKAP", vm.$route.params.id);
                vm.$router.push("/detail_donut/" + 2);
                vm.val = vm.series[2];
                vm.valLabels = vm.chartOptions.labels[2];
              } else if (config.dataPointIndex == 3) {
                localStorage.setItem("idKAP", vm.$route.params.id);
                vm.$router.push("/detail_donut/" + 3);
                vm.val = vm.series[3];
                vm.valLabels = vm.chartOptions.labels[3];
              } else if (config.dataPointIndex == 4) {
                localStorage.setItem("idKAP", vm.$route.params.id);
                vm.$router.push("/detail_donut/" + 4);
                vm.val = vm.series[4];
                vm.valLabels = vm.chartOptions.labels[4];
              }
            },
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
            },
          },
        },
        legend: {
          show: false,
        },
      },
    };
  },

  mounted() {
    this.getChart();
  },
  methods: {
    async getChart() {
      let vm = this;
      let charts = await axios.get("KAP/chartByKAPId/" + vm.$route.params.id, {
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      let forSeries = [];
      charts.data.data.forEach((el) => {
        forSeries.push(Number(el.count));
        // if (el.progres == "Start") {
        //   forSeries.push(el.count);
        // } else if (el.progres == "Fifty") {
        //   forSeries.push(el.count);
        // } else if (el.progres == "Eighty") {
        //   forSeries.push(el.count);
        // } else if (el.progres == "Over") {
        //   forSeries.push(el.count);
        // } else if (el.progress == "Canceled") {
        //   forSeries.push(Number(el.count));
        // } else if (el.progress == "Done") {
        //   forSeries.push(Number(el.count));
        // }
      });

      vm.series = forSeries;
      vm.theChart = charts.data.data;

      vm.val = vm.series[2];
      vm.valLabels = vm.chartOptions.labels[2];
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  .title {
    width: 100%;
    .legend {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: space-between;
      font-family: "nunito", sans-serif;
      font-weight: 700;
      font-size: clamp(14px, 16px, 18px);
      .ininya {
        padding: 0.5% 0;
        width: 32%;
        margin-bottom: 3%;
        text-align: center;
        border-radius: 6px;
      }
    }
  }
  .donut {
    font-family: "nunito", sans-serif;
    color: map-get($color, "color");
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute !important;
    .judul {
      font-family: "nunito", sans-serif;
      font-weight: 700;
      font-size: 28px;
      width: 100%;
      text-transform: capitalize;
      text-align: center;
    }
    .angka {
      position: absolute;
      margin-bottom: 12%;
      .persen {
        font-size: 48px;
        font-weight: 700;
        text-align: center;
      }
      .upah {
        font-size: 16px;
        text-align: center;
        font-weight: 400;
      }
    }
  }
}
</style>
