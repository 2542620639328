<template>
  <div>
    <b-modal hide-footer hide-header centered id="modalLog" size="lg">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="$bvModal.hide('modalLog')"
          style="background-color: white; border: none"
          ><img
            src="../../../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        style="
          text-align: center;
          font-weight: 700;
          font-family: 'nunito', sans-serif;
          font-size: 22px;
        "
      >
        Logging
      </div>
      <div
        class="logs"
        style="
            display: flex;
            align-items: center;
            font-family: 'Nunito', sans-serif;
            text-transform: capitalize;
            padding: 1% 0;
            border-bottom: 1px solid #b5bac3;
          "
        v-for="(log, mcr) in logs"
        :key="mcr"
      >
        <div class="nomor" style="margin-right: 2%">
          <span>{{ mcr + 1 }}.</span>
        </div>

        <div
          class="history"
          style="
              height: 80px;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
            "
        >
          <span style="font-weight: 600"
            >Aksi : {{ log.history }}
            <span v-if="log.invited != null"
              >-
              <span
                style="
                    text-transform: lowercase;
                    color: #327ff2;
                    text-decoration: underline;
                  "
                >{{ log.invited }}</span
              ></span
            ></span
          >

          <span>
            oleh :
            <span
              style="
                  text-transform: lowercase;
                  color: #327ff2;
                  font-weight: 600;
                  text-decoration: underline;
                "
            >
              {{ log.pengguna }}
            </span>
          </span>

          <span>tanggal : {{ log.createdAt }} WIB</span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    logs: {
      type: Array,
    },
  },
};
</script>

<style></style>
