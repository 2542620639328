<template>
  <div class="wrapper">
    <div class="nama">
      <span>profile KAP</span>
      <div class="button-wrapper2">
        <b-button @click="editKAP" v-if="role == 'admin' && loadingK != true"
          >Edit</b-button
        >
        <b-button
          @click="$bvModal.show('modalUserAP')"
          v-if="role == 'admin' && loadingK != true"
          >Edit AP</b-button
        >
      </div>
      <b-modal hide-footer hide-header centered ref="modal-edit" size="lg">
        <div class="tutup" style="display: flex; justify-content: flex-end">
          <b-button
            @click="tutupModal1"
            style="background-color: white; border: none"
            ><img
              src="../../../../assets/icon/close.svg"
              alt=""
              style="width: 15px; height: 15px"
          /></b-button>
        </div>
        <div
          class="content"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 1% 2%;
          "
        >
          <span
            class="tittle"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 28px;
              font-weight: 700;
              text-transform: capitalize;
            "
          >
            Edit {{ profileKAP.namaKAP }}
          </span>
          <div class="form" style="width: 95%; margin-top: 4%">
            <b-form-group
              label="No Ijin KAP"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="No Ijin KAP"
                style="border-radius: 10px"
                size="lg"
                type="text"
                v-model="profileKAP.noIjin"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Alamat KAP"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="Alamat KAP"
                style="border-radius: 10px"
                size="lg"
                type="text"
                v-model="profileKAP.alamatKAP"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Nomor Telepon KAP"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="Nomor Telepon KAP"
                style="border-radius: 10px"
                size="lg"
                type="number"
                v-model="profileKAP.telfon"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group
              label="Email KAP"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="Email KAP"
                style="border-radius: 10px"
                size="lg"
                type="text"
                v-model="profileKAP.emailKAP"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group
              label="Website KAP"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="Website KAP"
                style="border-radius: 10px"
                size="lg"
                type="text"
                v-model="profileKAP.website"
              >
              </b-form-input>
            </b-form-group>
          </div>
        </div>
        <div
          class="wrapper"
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 0 2%;
          "
        >
          <div
            class="button-wrapper"
            style="display: flex; width: 95%; justify-content: flex-end"
          >
            <b-button
              @click="doEdit"
              v-if="loading2 == false"
              style="
                background-color: #327ff2;
                color: #fff;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                width: 25%;
                border: none;
                margin-top: 20px;
                height: 40px;
              "
              >Edit KAP</b-button
            >
            <b-button
              disabled
              v-else
              style="
                background-color: #327ff2;
                color: #fff;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                width: 25%;
                border: none;
                margin-top: 20px;
                height: 40px;
              "
              ><white-spinner
            /></b-button>
          </div>
        </div>
      </b-modal>
    </div>

    <div class="details">
      <div class="rows">
        <span class="title">Nomor Ijin</span>
        <b-skeleton
          animation="throb"
          class="loading-isi"
          v-if="loadingK != false"
        ></b-skeleton>
        <span
          class="isi"
          v-else-if="profileKAP.noIjin == '' && loadingK != true"
          >nomor ijin belum diisi</span
        >
        <span class="isi" v-else>{{ profileKAP.noIjin }}</span>
      </div>

      <div class="rows">
        <span class="title">alamat</span>
        <b-skeleton
          animation="throb"
          class="loading-isi"
          v-if="loadingK != false"
        ></b-skeleton>
        <span
          class="isi"
          v-else-if="profileKAP.alamatKAP == '' && loadingK != true"
          >Alamat belum diisi</span
        >
        <span class="isi" v-else>{{ profileKAP.alamatKAP }}</span>
      </div>

      <div class="rows">
        <span class="title">telfon</span>
        <b-skeleton
          animation="throb"
          class="loading-isi"
          v-if="loadingK != false"
        ></b-skeleton>
        <span
          class="isi"
          v-else-if="profileKAP.telfon == null && loadingK != true"
          >nomor telepon belum diisi</span
        >
        <span class="isi" v-else>{{ profileKAP.telfon }}</span>
      </div>

      <div class="rows">
        <span class="title">email</span>
        <b-skeleton
          animation="throb"
          class="loading-isi"
          v-if="loadingK != false"
        ></b-skeleton>
        <span
          class="isi"
          v-else-if="profileKAP.emailKAP == '' && loadingK != true"
          >alamat email belum diisi</span
        >
        <span class="isi" v-else style="text-transform: inherit">{{
          profileKAP.emailKAP
        }}</span>
      </div>

      <div class="rows">
        <span class="title">website</span>
        <b-skeleton
          animation="throb"
          class="loading-isi"
          v-if="loadingK != false"
        ></b-skeleton>
        <span
          class="isi"
          v-else-if="profileKAP.website == '' && loadingK != true"
          >alamat website belum diisi</span
        >
        <a
          class="isi"
          v-else
          :href="'https://' + profileKAP.website"
          target="blank"
          style="
            text-decoration: underline;
            text-transform: inherit;
            color: #327ff2;
            cursor: pointer;
          "
          >{{ profileKAP.website }}</a
        >
      </div>

      <div class="rows">
        <span class="title">daftar AP</span>
        <b-skeleton
          animation="throb"
          class="loading-isi"
          v-if="loadingK != false"
        ></b-skeleton>
        <div class="isi" v-else>
          <span v-b-toggle.my-collapse style="text-decoration: underline"
            >Lihat Daftar AP</span
          >
          <b-collapse id="my-collapse">
            <span class="isisi" v-for="item in daftarAP" :key="item.nama">{{
              item.nama
            }}</span>
          </b-collapse>
        </div>
      </div>
    </div>

    <b-alert fade dismissible variant="success" :show="show" style=""
      >Berhasil Mengubah Data</b-alert
    >
    <ModalUserAP />
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import WhiteSpinner from "@/components/spinner/white-spinner.vue";
import { mapState } from "vuex";
import ModalUserAP from "./ModalUserKAP.vue";
export default {
  components: {
    WhiteSpinner,
    ModalUserAP,
  },
  data() {
    return {
      noIjin: "",
      alamatKAP: "",
      telefon: "",
      emailKAP: "",
      website: "",
      daftarAP: [],
      loading: false,
      loading2: false,
      show: false,
    };
  },
  computed: {
    ...mapState("apiProfileKAP", ["profileKAP", "role", "loadingK"]),
  },
  mounted() {
    this.getData();
    this.getUsers();
  },
  methods: {
    getData() {
      let vm = this;
      vm.loading = true;
      let idKAP = localStorage.getItem("idKAP");
      axios
        .get("KAP/listById/" + idKAP, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          vm.kap = res.data.respon[0];
          vm.user = res.data.jumlahUsers;
        });
    },
    getUsers() {
      let vm = this;
      let idKAP = localStorage.getItem("idKAP");
      let nama = "";
      axios
        .post(
          "KAP/usersByKAP",
          {
            id: idKAP,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          vm.daftarAP = res.data;
        });
    },
    editKAP() {
      let vm = this;
      let z = localStorage.getItem("token");
      let idKAP = localStorage.getItem("idKAP");
      axios
        .get("KAP/listById/" + idKAP, {
          headers: { token: z },
        })
        .then((res) => {
          vm.client = res.data[0];
        });
      this.$refs["modal-edit"].show();
    },
    tutupModal1() {
      this.$refs["modal-edit"].hide();
    },
    doEdit() {
      let vm = this;
      vm.loading2 = true;
      let x = localStorage.getItem("token");
      let idKAP = localStorage.getItem("idKAP");
      axios
        .post(
          "KAP/update/" + idKAP,
          {
            noIjin: vm.profileKAP.noIjin,
            alamatKAP: vm.profileKAP.alamatKAP,
            telfon: vm.profileKAP.telfon,
            emailKAP: vm.profileKAP.emailKAP,
            website: vm.profileKAP.website,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.loading2 = false;
            this.$refs["modal-edit"].hide();
            vm.show = true;
            setTimeout(() => {
              vm.show = false;
            }, 3000);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);
.wrapper {
  .nama {
    font-size: 28px;
    font-family: "nunito", sans-serif;
    font-weight: 300;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button-wrapper2 {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn {
        font-size: 14px;
        margin-right: 10px;
        background-color: map-get($color, "white2");
        border: 1.5px solid map-get($color, "blue");
        color: map-get($color, "blue");
        font-family: "nunito", sans-serif;
        font-weight: 700;
        height: 28px;
        display: flex;
        align-items: center;
        box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
      }
      .btn:hover {
        font-size: 14px;
        background-color: map-get($color, "blue");
        border: 1.5px solid map-get($color, "blue");
        color: map-get($color, "white");
        font-family: "nunito", sans-serif;
        font-weight: 700;
        height: 28px;
        display: flex;
        align-items: center;
        box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
      }
    }
  }
  .details {
    .rows {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1% 0;
      .title {
        font-family: "nunito", sans-serif;
        font-weight: 300;
        color: map-get($color, "gray-dark");
        text-transform: capitalize;
        width: 50%;
      }
      .isi {
        font-family: "nunito", sans-serif;
        font-weight: 300;
        color: map-get($color, "black");
        text-transform: capitalize;
        width: 50%;
        .isisi {
          display: flex;
          flex-direction: column;
        }
      }
      .loading-isi {
        width: 50%;
      }
    }
  }
  .alert {
    position: fixed;
    width: 40%;
    display: flex;
    justify-content: center;
    transform: translateX(30%);
    margin: auto;
  }
}
</style>
