<template>
  <div>
    <b-modal hide-footer hide-header centered id="modalUserAP" size="md">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="$bvModal.hide('modalUserAP')"
          style="background-color: white; border: none"
          ><img
            src="../../../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        class="table_AP"
        style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 1% 2%;
          "
      >
        <span
          class="tittle"
          style="
              font-family: 'nunito', sans-serif;
              font-size: 28px;
              font-weight: 700;
              text-transform: capitalize;
            "
        >
          Edit Daftar AP
        </span>
        <div class="tableAP">
          <TableUserKAP />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import TableUserKAP from "./TableUserKAP.vue";
export default {
  components: {
    TableUserKAP,
  },
};
</script>

<style></style>
© 2022 GitHub, Inc. Terms Privacy
